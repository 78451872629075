import React, {
  useState,
} from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faGooglePay,
  faApplePay,
} from '@fortawesome/free-brands-svg-icons';

import {
  faCreditCard,
  faPiggyBank,
} from '@fortawesome/free-solid-svg-icons';

export default function PayView() {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  return (
    <Container className="mb-4">
      <h1 className="text-center mb-4">Confirmation</h1>
      <p className="text-center mb-0 text-muted">
        Successfully registered at:
      </p>
      <p className="text-center mb-4 fw-bold">Lemur Hospital</p>
      <h3 className="text-center mb-4 fw-bold">$100.00 copay</h3>
      <Row className="mb-4">
        <Col sm={{ offset: 3, span: 6 }}>
          <ListGroup>
            <ListGroup.Item
              role="button"
              className="d-flex align-items-center border rounded shadow-sm mb-3 px-4 py-3"
              onClick={() => setSelectedPaymentMethod('Google Pay')}
              active={selectedPaymentMethod === 'Google Pay'}
            >
              <FontAwesomeIcon className="text-info" icon={faGooglePay} size="4x" />
              <span className="ms-3">Pay with Google Pay</span>
            </ListGroup.Item>
            <ListGroup.Item
              role="button"
              className="d-flex align-items-center border rounded shadow-sm mb-3 px-4 py-3"
              onClick={() => setSelectedPaymentMethod('Apple Pay')}
              active={selectedPaymentMethod === 'Apple Pay'}
            >
              <FontAwesomeIcon className="text-info" icon={faApplePay} size="4x" />
              <span className="ms-3">Pay with Apple Pay</span>
            </ListGroup.Item>
            <ListGroup.Item
              role="button"
              className="d-flex align-items-center border rounded shadow-sm mb-3 px-4 py-3"
              onClick={() => setSelectedPaymentMethod('Credit Card')}
              active={selectedPaymentMethod === 'Credit Card'}
            >
              <FontAwesomeIcon className="text-info" icon={faCreditCard} size="4x" />
              <span className="ms-3">Pay with Credit Card (9999)</span>
            </ListGroup.Item>
            <ListGroup.Item
              role="button"
              className="d-flex align-items-center border rounded shadow-sm mb-3 px-4 py-3"
              onClick={() => setSelectedPaymentMethod('Health Savings Account')}
              active={selectedPaymentMethod === 'Health Savings Account'}
            >
              <FontAwesomeIcon className="text-info" icon={faPiggyBank} size="4x" />
              <span className="ms-3">Pay with Health Savings Account</span>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={{ offset: 3, span: 6 }}>
          <Button
            variant="primary"
            className="w-100 mb-3"
            disabled={!selectedPaymentMethod}
          >
            Select Payment Method
          </Button>
          <Button variant="outline-primary" className="w-100">Pay Later</Button>
        </Col>
      </Row>
    </Container>
  );
}
