import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import {
  Link,
  useNavigate,
} from 'react-router-dom';

export default function PerscriptionView() {
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: 'Z-Pak',
    dose: '250mg',
    prescriber: 'Dr. John Smith',
    conditionTreated: 'Strep Throat',
  });
  const navigate = useNavigate();

  return (
    <Container>
      <Row className="my-4">
        <Col className="d-flex flex-column align-items-center">
          <Image src="/icons/capsule.png" alt="Capsule" width="60" height="60" />
          <h1>Z-Pak</h1>
          <p>250mg, once per day</p>
          <p className="text-info fw-bold">NEW Rx prescribed on 11/04/2024</p>
          <Link to="/perscription/purchase" className="btn btn-info px-4">
            Purchase
          </Link>
        </Col>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            {isEditing ? (
              <>
                <Form>
                  <FloatingLabel label="Name" className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      className="border-0 border-bottom rounded-0 border-info"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Dose" className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Dose"
                      className="border-0 border-bottom rounded-0 border-info"
                      value={formData.dose}
                      onChange={(e) => setFormData({ ...formData, dose: e.target.value })}
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Prescriber" className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Prescriber"
                      className="border-0 border-bottom rounded-0 border-info"
                      value={formData.prescriber}
                      onChange={(e) => setFormData({ ...formData, prescriber: e.target.value })}
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Condition Treated" className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Condition Treated"
                      className="border-0 border-bottom rounded-0 border-info"
                      value={formData.conditionTreated}
                      onChange={(e) => setFormData({
                        ...formData,
                        conditionTreated: e.target.value,
                      })}
                    />
                  </FloatingLabel>
                </Form>
                <Row className="mt-4">
                  <Col>
                    <Button variant="primary" className="w-100" onClick={() => setIsEditing(false)}>Save Changes</Button>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <ListGroup variant="flush">
                  <ListGroup.Item className="border-0">
                    <p className="text-muted mb-0">Dose</p>
                    <p className="fw-bold">250mg</p>
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    <p className="text-muted mb-0">Frequency</p>
                    <p className="fw-bold">Once per day</p>
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    <p className="text-muted mb-0">Refills</p>
                    <p className="fw-bold">No</p>
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    <p className="text-muted mb-0">Fill by</p>
                    <p className="fw-bold">11/4/2024</p>
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    <p className="text-muted mb-0">Condition Treated</p>
                    <p className="fw-bold">Strep Throat</p>
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    <p className="text-muted mb-0">Prescriber</p>
                    <p className="fw-bold">Dr. John Smith</p>
                  </ListGroup.Item>
                </ListGroup>
                <Row>
                  <Col>
                    <Button variant="primary" className="px-4 w-100" onClick={() => setIsEditing(true)}>Edit</Button>
                  </Col>
                  <Col>
                    <Button variant="info" className="px-4 w-100" onClick={() => setShowModal(true)}>Remove Medication</Button>
                  </Col>
                </Row>
              </>
            )}
          </Card.Body>
        </Card>
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header className="bg-primary text-white" closeButton>
          <Modal.Title>Remove Medication</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-4">
          Are you sure you want to remove this medication?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate('/my-data')}>Remove</Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Go Back</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
