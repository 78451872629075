import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import Card from 'react-bootstrap/Card';

export default function DiagnosesCard({
  diagnosis,
  date,
  onClick,
  className,
}) {
  return (
    <Card
      className={`shadow pt-2 border-0 w-200 h-100 ${className} diagnoses-card`}
      role="button"
      onClick={onClick}
      style={{ backgroundColor: '#5e63f1', minWidth: '250px', marginRight: '20px' }}
    >
      <Card.Body className="pb-1 pb-sm-1 pt-2 d-flex flex-column align-items-start">
        <div className="chevron-container">
          <FontAwesomeIcon icon={faChevronRight} className="ms-auto mt-2 text-dark" />
        </div>
        <Card.Title style={{ color: 'white' }} className="text-center mt-auto text-sm-start diagnoses-card__date">{date}</Card.Title>
        <Card.Title style={{ color: 'white' }} className="text-center mt-auto text-sm-start diagnoses-card__title">{diagnosis}</Card.Title>
      </Card.Body>
    </Card>
  );
}

DiagnosesCard.propTypes = {
  diagnosis: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

DiagnosesCard.defaultProps = {
  className: '',
};
