import React, {
  useEffect,
  useState,
} from 'react';
import {
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';

import PermissionContext from '../../context/permission';
import ToastContext from '../../context/toast';
import PageHeader from '../page-header/page-header';
import PageNavigation from '../page-navigation/page-navigation';

export default function SiteLayout() {
  const user = useLoaderData();
  const [toast, setToast] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [showTour, setShowTour] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setShowToast(!!toast);
  }, [toast]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <PermissionContext.Provider value={user.permissions}>
      <ToastContext.Provider value={setToast}>
        <div className="site-layout">
          <header data-testid="site-header">
            <PageHeader user={user} />
          </header>
          <PageNavigation variant="light" user={user} navigate={navigate} />
          <main className="main site-main" data-testid="site-main">
            <Row>
              <Col lg={{ offset: 1, span: 10 }} xl={{ offset: 2, span: 8 }}>
                <Outlet
                  context={{
                    showTour,
                    setShowTour,
                    user,
                  }}
                />
              </Col>
            </Row>
            <ToastContainer
              position="top-end"
              className="me-3 mt-3 position-fixed"
            >
              <Toast
                show={showToast}
                onClose={() => setToast(null)}
                delay={4000}
                bg={toast?.bg}
                autohide
              >
                <Toast.Header closeButton>
                  <strong className="me-auto">{toast?.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast?.body}</Toast.Body>
              </Toast>

            </ToastContainer>
          </main>
        </div>
      </ToastContext.Provider>
    </PermissionContext.Provider>
  );
}
