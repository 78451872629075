import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';

import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import { Steps } from 'intro.js-react';

import {
  useOutletContext,
  useNavigate,
} from 'react-router-dom';

import request from '../../utils/request';
import InfoIcon from '../../components/info-icon/info-icon';

function easeOutExpo(t, b, c, d) {
  /* eslint-disable-next-line no-mixed-operators, eqeqeq */
  return (t == d) ? b + c : c * (-(2 ** (-10 * t / d)) + 1) + b;
}

export default function CheckSymptomsView() {
  const [symptoms, setSymptoms] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timerStart, setTimerStart] = useState(0);
  const { showTour, setShowTour } = useOutletContext();
  const symptomsRef = useRef(null);
  const assessmentRef = useRef(null);
  const navigate = useNavigate();

  const steps = [
    {
      element: '.text-symptoms',
      intro: '<strong>Check Symptoms</strong>: Describe your symptoms to get a diagnosis from our Virtual Doctor.',
    },
  ];

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const fetchData = async () => {
      const data = await request('/api/virtual-doctor/assessment', {
        method: 'POST',
        body: {
          prompt: symptoms,
        },
      });
      setLoading(false);
      setDiagnosis(data);
    };

    setProgress(0);
    setTimerStart(Date.now());
    setDiagnosis(null);
    setLoading(true);
    fetchData();
  }, [symptoms, diagnosis]);

  useEffect(() => {
    const totalTime = 10000;
    const timer = setInterval(() => {
      const elapsedTime = Date.now() - timerStart;
      const percentageTime = elapsedTime / totalTime;
      const newProgress = easeOutExpo(percentageTime, 0, 100, 10);

      setProgress(newProgress);

      if (elapsedTime !== Math.abs(elapsedTime)) clearInterval(timer);
    }, 1000 / 20);

    return () => clearTimeout(timer);
  }, [timerStart]);

  useEffect(() => {
    symptomsRef.current?.focus();
  }, []);

  useEffect(() => {
    if (!diagnosis) return;

    assessmentRef.current?.scrollIntoView();
  }, [diagnosis]);

  return (
    <Container>
      <Steps
        enabled={showTour}
        exitOnEsc={false}
        steps={steps}
        initialStep={0}
        onExit={() => setShowTour(false)}
        options={{
          showBullets: false,
        }}
      />
      <Row className="mb-3">
        <h1 className="text-white fw-bold" style={{ fontSize: '2.5rem' }}>Virtual Doctor</h1>
      </Row>
      <Row>
        {!loading && !diagnosis && (
        <Col sm={4}>
          <Card className="mb-3 shadow-sm">
            <Card.Img variant="top" src="/images/virtual-doctor-avatar2.png" />
            <Card.Body>
              <Card.Text>
                Hello!  Tell me the symptoms you are experiencing and
                I will provide you with an initial assessment of your condition.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        )}
        <Col sm={(!loading && !diagnosis) ? 8 : 12}>
          {!loading && (
          <Card className="mb-3 shadow-sm">
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <span className="me-2">Symptoms</span>
                    <InfoIcon tooltip="Describe your symptoms, potential causes and other details that will help the doctor correctly assess your condition." />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="5"
                    className="text-symptoms"
                    placeholder="Enter the symptoms you are experiencing..."
                    value={symptoms}
                    onChange={(e) => setSymptoms(e.target.value)}
                    ref={symptomsRef}
                  />
                </Form.Group>
                <Button type="submit">Ask the Doctor</Button>
              </Form>
            </Card.Body>
          </Card>
          )}

          {loading && (
            <>
              <Card className="mb-3 shadow-sm">
                <Card.Body>
                  <Card.Title>Symptoms</Card.Title>
                  <Card.Text>{symptoms || 'N/A'}</Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-3 shadow-sm">
                <Card.Body>
                  <Card.Title>
                    <FontAwesomeIcon icon={faSpinner} spin className="text-primary" />
                &nbsp;
                    <span>Consulting Virtual Doctor</span>
                  </Card.Title>
                  <Card.Subtitle className="mb-3">Please wait as this could take up to 30 seconds...</Card.Subtitle>
                  <Card.Text>
                    Once this process is complete,
                    review the initial assessment and determine
                    if you should find care.
                  </Card.Text>
                  <ProgressBar now={progress} striped animated />
                </Card.Body>
              </Card>

              <Card className="mb-3">
                <Card.Body>
                  <Card.Title className="mb-3">
                    Your initial assessment will contain the following information.
                  </Card.Title>
                </Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item>Potential Causes</ListGroup.Item>
                  <ListGroup.Item>Potential Evaluation</ListGroup.Item>
                  <ListGroup.Item>Potential Treatments</ListGroup.Item>
                  <ListGroup.Item>Potential Severity</ListGroup.Item>
                  <ListGroup.Item>Follow-up Questions</ListGroup.Item>
                </ListGroup>
              </Card>
            </>
          )}

          {diagnosis && (
            <>
              <Card className="mb-3 shadow-sm">
                <Card.Body className="pb-0">
                  <Card.Title>Next Steps</Card.Title>
                </Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item className="d-flex align-items-center">
                    <Button className="text-nowrap" style={{ minWidth: '125px' }} onClick={() => navigate('/find-care')}>Find Care</Button>
                    <span className="ms-3">Locate a provider near you to follow up.</span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex align-items-center">
                    <Button className="text-nowrap" style={{ minWidth: '125px' }} variant="outline-primary" onClick={() => symptomsRef.current.scrollIntoView()}>Search Again</Button>
                    <span className="ms-3">Start a new conversation or update your symptoms for another assessment.</span>
                  </ListGroup.Item>
                </ListGroup>
              </Card>

              <Card ref={assessmentRef} className="mb-3 shadow-sm">
                <Card.Body>
                  <Card.Title>Initial Assessment</Card.Title>
                  <hr />
                  <Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
                    {diagnosis.message.content}
                  </Markdown>
                </Card.Body>
              </Card>

              <Card className="mb-3 shadow-sm">
                <Card.Body className="pb-0">
                  <Card.Title>Next Steps</Card.Title>
                </Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item className="d-flex align-items-center">
                    <Button className="text-nowrap" style={{ minWidth: '125px' }} onClick={() => navigate('/find-care')}>Find Care</Button>
                    <span className="ms-3">Locate a provider near you to follow up.</span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex align-items-center">
                    <Button className="text-nowrap" style={{ minWidth: '125px' }} variant="outline-primary" onClick={() => symptomsRef.current.scrollIntoView()}>Search Again</Button>
                    <span className="ms-3">Start a new conversation or update your symptoms for another assessment.</span>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
