import React, {
  useEffect,
} from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';

import {
  Link,
  useNavigate,
} from 'react-router-dom';

export default function SyncView() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => navigate('/'), 700);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Navbar bg="white" expand="md" className="border-bottom mb-4">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img src="/images/QwikCare_logo.jpeg" alt="QwikCare" height="40px" style={{ borderRadius: '5px' }} />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        <Row className="mt-5 mb-5">
          <Col md={{ offset: 4, span: 4 }} className="text-center">
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title>Gathering your information</Card.Title>
                <div className="sync" />
                <div className="text-container">
                  <div className="text-slide">
                    <div>Authenticating...</div>
                    <div>EMR&apos;s...</div>
                    <div>&nbsp;</div>
                    <div>Insurance Companies...</div>
                    <div>Pharmacies...</div>
                    <div>Public Health Records...</div>
                    <div>Uploading Data</div>
                  </div>
                </div>

              </Card.Body>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}
