import React from 'react';

import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';

import {
  Link,
} from 'react-router-dom';

import NotificationItem from './notification-item';

export default function Notifications() {
  return (
    <Card className="border-0">
      <Card.Body className="px-0">
        <Card.Title className="d-flex justify-content-between">
          <span>Notifications</span>
          <Link to="/notifications" className="text-dark text-decoration-none" style={{ fontSize: '1rem' }}>See all</Link>
        </Card.Title>
        <ListGroup variant="flush">
          <NotificationItem
            icon={<Image src="/icons/capsule.png" alt="Capsule" width="30" height="30" />}
            title="Z-Pak"
            description="Purchase"
            to="/perscription"
          />
          <NotificationItem
            icon={<span style={{ fontSize: '2rem' }}>✉️</span>}
            title="New Message"
            description="You have received a new message"
          />
          <NotificationItem
            icon={<span style={{ fontSize: '2rem' }}>🗓️</span>}
            title="Upcoming Appointment"
            description="Prepare for an upcoming appointment"
          />
        </ListGroup>
      </Card.Body>
    </Card>
  );
}
