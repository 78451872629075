import React, {
  useMemo,
} from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';

import { Steps } from 'intro.js-react';

import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';

import CalendarRow from '../../components/calendar-row/calendar-row';
import DailyCheckin from '../../components/daily-checkin/daily-checkin';
import NavigationCard from '../../components/navigation-card/navigation-card';
import Notifications from '../../components/notifications/notifications';

export default function HomeView() {
  const { showTour, user } = useOutletContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const profileImage = useMemo(() => {
    if (user.People && user.People.length && user.People[0]?.Content?.path) {
      return user.People[0]?.Content?.path;
    }
    return 'https://www.jea.com/cdn/images/avatar/avatar-alt.svg';
  }, [user]);

  const steps = useMemo(() => {
    const s = searchParams.get('step');

    if (!s) {
      return [
        {
          element: '.action-my-data',
          intro: 'Welcome to QwikCare!  Start your health journey by accessing <strong>My Data</strong> which contains your health profile.',
        },
      ];
    }
    return [
      {
        element: '.search-lemur',
        intro: 'Search our database or initiate a conversation with our Virtual Doctor.',
      },
      {
        element: '.action-check-symptoms',
        intro: 'Next, Let&apos;s perform a self-diagnosis with our Virtual Doctor.',
      },
    ];
  }, [searchParams]);

  return (
    <Container className="px-lg-5 home-view">
      <Steps
        enabled={showTour}
        exitOnEsc={false}
        steps={steps}
        initialStep={0}
        onExit={() => {}}
        options={{
          showBullets: false,
        }}
      />
      <Row className="user-profile mb-3">
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <div className="d-flex flex-row ps-4 align-items-center mb-2 position-relative gap-2">
            <Image
              src={profileImage}
              className="object-fit-contain bg-white border border-3"
              roundedCircle
              style={{ height: '90px', width: '90px' }}
            />
            <div>
              <h1 className="fw-bold mb-0">
                {`Hello ${user.firstName}`}
              </h1>
              <h5>How are you feeling today?</h5>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <DailyCheckin />
        </Col>
      </Row>
      <Row>
        <CalendarRow />
      </Row>
      <Row className="search-lemur pb-4 px-2 bg-white">
        <Col>
          <Form className="mb-3">
            <InputGroup size="lg" className="border border-1 rounded-1">
              <InputGroup.Text className="search-lemur text-dark bg-light rounded-start-1 border-end-0 pe-1">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search LEMUR"
                className="rounded-end-1 border-start-0 bg-light text-dark placeholder-dark"
              />
            </InputGroup>
          </Form>
        </Col>
      </Row>
      <Row className="nav-options pb-4 px-2 bg-white">
        <Col>
          <Row xs={3} md={3} className="mb-2 align-items-stretch">
            <Col className="mb-3 px-2">
              <NavigationCard
                alertCount={3}
                icon="/icons/folder.png"
                title="My Data"
                description="Review your personal health profile."
                onClick={() => navigate('/my-data')}
              />
            </Col>
            <Col className="mb-3 px-2">
              <NavigationCard
                icon="/icons/scan-qr-code.png"
                title="Scan"
                description="Use your camera to scan a QR code."
                onClick={() => navigate('/scan')}
              />
            </Col>
            <Col className="mb-3 px-2">
              <NavigationCard
                icon="/icons/symptom-checker.png"
                title="Virtual Doctor"
                description="Get an initial diagnosis from our Virtual Doctors"
                onClick={() => navigate('/check-symptoms')}
              />
            </Col>
          </Row>
          <Row xs={3} md={3} className="mb-2 align-items-stretch">
            <Col className="mb-3 px-2">
              <NavigationCard
                icon="/icons/social-groups.png"
                title="Social Groups"
                description="Connect with groups for additional support."
                onClick={() => navigate('/social-groups')}
              />
            </Col>
            <Col className="mb-3 px-2">
              <NavigationCard
                icon="/icons/heartbeat.png"
                title="My Health"
                description="Verify your health information."
                onClick={() => navigate('/my-health')}
              />
            </Col>
            <Col className="mb-3 px-2">
              <NavigationCard
                icon="/icons/first-aid-kit.png"
                title="Find Care"
                description="Search the best provider(s) for you."
                onClick={() => navigate('/find-care')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="notifications pb-4 px-2 bg-white rounded-bottom-3">
        <Col>
          <Notifications />
        </Col>
      </Row>
    </Container>
  );
}
