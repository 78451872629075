import React, { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';

import {
  Link,
  useNavigate,
} from 'react-router-dom';

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function GatherEmail({
  email, setEmail, onComplete, onCancel,
}) {
  const isValid = useMemo(() => {
    if (!email) {
      return false;
    }
    return !!EMAIL_REGEX.test(email);
  }, [email]);

  return (
    <Col sm={{ offset: 3, span: 6 }}>
      <Card>
        <Card.Body className="pt-4">
          <Card.Text className="text-center mb-4">
            You can share your records with another party.
            Please enter the email address of the recipient.
            <br />
            We will send a verification email to the recipient.
          </Card.Text>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Button disabled={!isValid} variant="primary" className="w-100" onClick={onComplete}>Continue</Button>
            </Form.Group>
            <Form.Group className="mb-3">
              <Button variant="secondary" className="w-100" onClick={onCancel}>Cancel</Button>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Col>
  );
}

GatherEmail.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

function ConfirmShareRecords({ email, onComplete, onCancel }) {
  return (
    <Col sm={{ offset: 3, span: 6 }}>
      <Card>
        <Card.Body className="pt-4">
          <Card.Text className="text-center mb-4">
            <p>You are requesting to share records to:</p>
            <strong>{email}</strong>
          </Card.Text>
          <Button as={Link} to="/scan/pay" variant="primary" className="w-100" onClick={onComplete}>Confirm</Button>
          <Button onClick={onCancel} variant="transparent" className="w-100">Cancel</Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

ConfirmShareRecords.propTypes = {
  email: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

function SharedRecordsConfirmation({ email, onComplete }) {
  return (
    <Col sm={{ offset: 3, span: 6 }}>
      <Card>
        <Card.Body className="pt-4">
          <Card.Text className="text-center mb-4">
            <p>
              We&apos;ve sent your share request to for verification:
              <strong>{email}</strong>
            </p>
          </Card.Text>
          <Button variant="primary" className="w-100" onClick={onComplete}>Continue</Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

SharedRecordsConfirmation.propTypes = {
  email: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default function ShareRecordsView() {
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const cancel = () => navigate('/share-records');
  const done = () => navigate('/home');
  const nextStep = () => setStep((currStep) => currStep + 1);

  return (
    <Container className="py-4">
      <h1 className="text-center mb-4">Share Records</h1>
      <Row>
        {step === 0 && (
          <GatherEmail
            email={email}
            setEmail={setEmail}
            onComplete={nextStep}
            onCancel={cancel}
          />
        )}
        {step === 1 && (
          <ConfirmShareRecords
            email={email}
            onComplete={nextStep}
            onCancel={cancel}
          />
        )}
        {step === 2 && (
          <SharedRecordsConfirmation
            email={email}
            onComplete={done}
          />
        )}
      </Row>
    </Container>
  );
}
