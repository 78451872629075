import React, {
} from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import { Steps } from 'intro.js-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencil,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import {
  useOutletContext,
} from 'react-router-dom';

export default function MyDataView() {
  const { showTour } = useOutletContext();
  const steps = [
    {
      element: '.my-data__list',
      intro: '<strong>My Data</strong>: Your health profile contains information that helps you and your provider keep track of your health.  Review this information and click the <strong>Verify</strong> button to continue.',
    },
  ];

  return (
    <Container className="my-data px-4">
      <Steps
        enabled={showTour}
        steps={steps}
        initialStep={0}
        onExit={() => {}}
        options={{
          showBullets: false,
        }}
      />
      <Row className="heading-row mb-3">
        <h5 className="mb-0">Patient Medical Record</h5>
        <h1 className="fw-bold" style={{ fontSize: '2.5rem' }}>Jane Doe</h1>
      </Row>
      <ButtonGroup className="w-100 shadow-sm mb-4">
        <Button className="bg-white d-flex flex-column align-items-center border-0">
          <span className="text-muted mt-auto" style={{ fontSize: '.8rem' }}>Gender</span>
          <span className="text-primary fw-bold">Female</span>
        </Button>
        <Button className="bg-white d-flex flex-column align-items-center border-start-1 border-bottom-0 border-top-0 border-end-0 border-light">
          <span className="text-muted mt-auto" style={{ fontSize: '.8rem' }}>Race</span>
          <span className="text-primary fw-bold">Black</span>
        </Button>
        <Button className="bg-white d-flex flex-column align-items-center border-start-1 border-end-0 border-bottom-0 border-top-0 border-light">
          <span className="text-muted mt-auto" style={{ fontSize: '.8rem' }}>Birthdate</span>
          <span className="text-primary fw-bold">08/29/1998</span>
        </Button>
      </ButtonGroup>
      <Row>
        <Col>
          <Accordion className="my-data__list" defaultActiveKey={['0', '1']} alwaysOpen>
            <Accordion.Item eventKey="0" className="border-0">
              <Accordion.Header className="position-relative">
                <div>Medications</div>
                <div className="position-absolute" style={{ right: '3.2rem' }}>
                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: '1.2rem' }} className="ms-2" />
                </div>
              </Accordion.Header>
              <Accordion.Body className="pe-0 pb-4 pt-2">
                <Container className="px-0">
                  <Row className="overflow-auto d-flex flex-row flex-nowrap px-0 flex-lg-wrap" style={{ scrollbarWidth: 'none' }}>
                    <Col className="px-0 mx-1 mb-3">
                      <Card style={{ minWidth: '220px' }} className="shadow-sm">
                        <Card.Body>
                          <Image src="/icons/tablet.png" width="35" height="35" className="mb-3" />
                          <Card.Title className="fw-bold mb-0" style={{ fontSize: '1rem' }}>Paxil</Card.Title>
                          <Card.Text>20mg, once per day</Card.Text>
                          <Card.Text className="mb-0 text-muted">Condition: Depression</Card.Text>
                          <Card.Text className="mb-0 text-muted">Refills: Yes</Card.Text>
                          <Card.Text className="text-muted">Renew by: 3/14/2025</Card.Text>
                          <Card.Text className="text-light d-flex justify-content-between">
                            <span>Dr. John Smith</span>
                            <span>9/7/2024</span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="px-0 mx-1">
                      <Card style={{ minWidth: '220px' }} className="shadow-sm">
                        <Card.Body>
                          <Image src="/icons/capsule.png" width="35" height="35" className="mb-3" />
                          <Card.Title className="fw-bold mb-0" style={{ fontSize: '1rem' }}>Prenatal Vitamins</Card.Title>
                          <Card.Text>once per day</Card.Text>
                          <Card.Text className="mb-0 text-muted">Condition: Pregnancy</Card.Text>
                          <Card.Text className="mb-0 text-muted">Refills: Yes</Card.Text>
                          <Card.Text className="text-muted">Renew by: 1/18/2025</Card.Text>
                          <Card.Text className="text-light d-flex justify-content-between">
                            <span>Dr. Jane Doe</span>
                            <span>7/9/2024</span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="px-0 mx-1">
                      <Card style={{ minWidth: '220px' }} className="shadow-sm">
                        <Card.Body>
                          <Image src="/icons/tablet.png" width="35" height="35" className="mb-3" />
                          <Card.Title className="fw-bold mb-0" style={{ fontSize: '1rem' }}>Z-Pak</Card.Title>
                          <Card.Text>250mg, once per day</Card.Text>
                          <Card.Text className="mb-0 text-muted">Condition: Strep Throat</Card.Text>
                          <Card.Text className="mb-0 text-muted">Refills: No</Card.Text>
                          <Card.Text className="text-muted">Fill by: 11/4/2024</Card.Text>
                          <Card.Text className="text-light d-flex justify-content-between">
                            <span>Dr. John Smith</span>
                            <span>11/3/2024</span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="position-relative">
                <div>Allergies</div>
                <div className="position-absolute" style={{ right: '3.2rem' }}>
                  <FontAwesomeIcon icon={faPencil} style={{ fontSize: '1.2rem' }} />
                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: '1.2rem' }} className="ms-2" />
                </div>
              </Accordion.Header>
              <Accordion.Body className="px-0 pt-0 pb-4">
                <Table striped borderless>
                  <thead>
                    <tr>
                      <th>Allergen</th>
                      <th>Reaction</th>
                      <th>Severity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Penicillin</td>
                      <td>Hives</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>Bee Venom</td>
                      <td>Hives, Swelling</td>
                      <td>6</td>
                    </tr>
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="position-relative">
                <div>Immunizations</div>
                <div className="position-absolute" style={{ right: '3.2rem' }}>
                  <FontAwesomeIcon icon={faPencil} style={{ fontSize: '1.2rem' }} />
                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: '1.2rem' }} className="ms-2" />
                </div>
              </Accordion.Header>

              <Accordion.Body className="p-0">
                {/* <Table striped borderless>
                  <thead>
                    <th>Name</th>
                    <th>Last Received</th>
                    <th>Next Due</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>A</td>
                      <td>B</td>
                      <td>C</td>
                    </tr>
                  </tbody>
      </Table> */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}
