import React, {
} from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { Steps } from 'intro.js-react';

import {
  useNavigate,
  useOutletContext,
} from 'react-router-dom';

import DiagnosesCard from '../../components/diagnoses-card/diagnoses-card';
import ConditionCard from '../../components/condition-card/condition-card';
// import Notifications from '../../components/notifications/notifications';

export default function MyHealthView() {
  const { showTour } = useOutletContext();
  const navigate = useNavigate();
  const steps = [
    {
      element: '.my-health__list',
      intro: '<strong>My Health</strong>: Your health profile contains information that helps you and your provider keep track of your health.  Review this information and click the <strong>Verify</strong> button to continue.',
    },
  ];
  return (
    <Container className="my-health px-4">
      <Steps
        enabled={showTour}
        steps={steps}
        initialStep={0}
        onExit={() => {}}
        options={{
          showBullets: false,
        }}
      />
      <Row className="diagnoses pb-1 px-2 bg-white rounded-bottom-3">
        <Col style={{ color: '#5e63f1' }}>
          My Diagnoses
        </Col>
      </Row>
      <Row>
        <div style={{ overflowX: 'auto', paddingBottom: '20px' }}>
          <div style={{ display: 'inline-flex' }}>
            {/* Need to replace with data fill */}
            <DiagnosesCard
              diagnosis="Hypertension"
              date="Diagnosed 1/15/2009"
              onClick={() => navigate('/diagnosis')}
            />
            <DiagnosesCard
              diagnosis="Diabetes"
              date="Diagnosed 2/19/2013"
              onClick={() => navigate('/diagnosis')}
            />
            <DiagnosesCard
              diagnosis="Depression"
              date="Diagnosed 2/19/2015"
              onClick={() => navigate('/diagnosis')}
            />
          </div>
        </div>
      </Row>
      <Row className="px-2 heading-row mb-3 pt-2">
        <Col style={{ color: '#5e63f1' }}>
          Condition History
        </Col>
      </Row>
      <Row>
        <Col>
          {/* Need to replace with data fill */}
          <Row className="pb-4">
            <ConditionCard
              condition="Left Leg Facture"
              provider="Dr. Amy Smith"
              providerImage="/images/doc1.png"
              icon="/images/crutches.png"
              date="Feb 9, 2021"
              onClick={() => navigate('/my-health')}
            />
          </Row>
          <Row className="pb-4">
            <ConditionCard
              condition="Acute Respiratory Infection"
              provider="Dr. Tom Brown"
              providerImage="/images/doc2.png"
              icon="/images/lungs.png"
              date="November 26, 2020"
              onClick={() => navigate('/my-health')}
            />
          </Row>
          <Row className="pb-4">
            <ConditionCard
              condition="Diabetic Neuropathy"
              provider="Dr. Mary Doe"
              providerImage="/images/doc3.png"
              icon="/images/syringe.png"
              date="October 12, 2020"
              onClick={() => navigate('/my-health')}
            />
          </Row>
          <Row className="pb-4">
            <ConditionCard
              condition="Mini Stroke"
              provider="Dr. James Cooper"
              providerImage="/images/doc4.png"
              icon="/images/brain.png"
              date="July 1, 2019"
              onClick={() => navigate('/my-health')}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
