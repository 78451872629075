import React from 'react';
import PropTypes from 'prop-types';

import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import {
  Link,
} from 'react-router-dom';

export default function NotificationItem({
  icon, title, description, to,
}) {
  return (
    <ListGroup.Item
      as={to ? Link : 'div'}
      className="d-flex mb-2 bg-secondary align-items-center rounded-2"
      to={to}
    >
      <span style={{ fontSize: '2rem' }}>{icon}</span>
      <div className="ms-3">
        <div className="fw-bold">{title}</div>
        <span className="text-info">{description}</span>
      </div>
      <FontAwesomeIcon icon={faChevronRight} className="ms-auto mt-2 text-dark" />
    </ListGroup.Item>
  );
}

NotificationItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  to: PropTypes.string,
};

NotificationItem.defaultProps = {
  to: '',
};
