import React from 'react';
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/Image';

import Card from 'react-bootstrap/Card';

export default function SocialCard({
  socialName,
  dateJoined,
  nextMeetingDate,
  isMeetingToday,
  isMessagePending,
  onClick,
  className,
}) {
  return (
    <Card
      className={`shadow pt-2 border-0 w-200 h-100 ${className} social-card`}
      role="button"
      onClick={onClick}
      style={{ minWidth: '250px', minHeight: '130px', marginRight: '20px' }}
    >
      <Card.Body className="pb-1 pb-sm-1 pt-2 d-flex flex-column align-items-start pl-40">
        <div
          style={{
            position: 'absolute',
            left: '14px',
            top: '35px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Card.Img src={isMessagePending ? '/images/message-active.png' : '/images/message-inactive.png'} variant="right" style={{ width: '20px', height: '20px' }} className="m-auto mt-3" />
        </div>
        <Card.Title style={{ color: 'black', fontWeight: 'bold', marginLeft: '30px' }} className="ml-20 social-card__title">{socialName}</Card.Title>
        <div className="d-flex flex-row align-items-start" style={{ marginLeft: '30px' }}>
          <Image
            src="/images/clock.png"
            className="object-fit-contain bg-white border border-0"
            style={{ height: '22px', width: '22px' }}
          />
          <Card.Text style={{ color: 'grey' }} className="ms-2 text-center mt-auto text-sm-start social-card__doc">{dateJoined}</Card.Text>
        </div>
        <div className="d-flex flex-row align-items-start" style={{ marginLeft: '30px' }}>
          <Image
            src={isMeetingToday ? '/images/calendar-active.png' : '/images/calendar-inactive.png'}
            className="object-fit-contain bg-white border border-0"
            style={{ height: '22px', width: '20px', marginTop: '4px' }}
          />
          <Card.Text style={{ color: isMeetingToday ? '#43c2d3' : 'grey' }} className="ms-2 text-center mt-auto text-sm-start social-card__doc">{nextMeetingDate}</Card.Text>
        </div>
        <div
          style={{
            position: 'absolute',
            right: '14px',
            top: '38px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Card.Img src="/images/Leave-Button.png" variant="right" style={{ width: '60px', height: '20px' }} className="m-auto mt-3" />
        </div>
      </Card.Body>
    </Card>
  );
}

SocialCard.propTypes = {
  socialName: PropTypes.string.isRequired,
  dateJoined: PropTypes.string.isRequired,
  nextMeetingDate: PropTypes.string.isRequired,
  isMeetingToday: PropTypes.bool.isRequired,
  isMessagePending: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SocialCard.defaultProps = {
  className: '',
};
