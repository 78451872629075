import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

// import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faMagnifyingGlass,
  faHouse,
  faQrcode,
  faFolderOpen,
  faUserGroup,
  faHeartPulse,
  faNotesMedical,
  faShare,
  faCalendar,
  faKey,
  faUser,
  faCircleQuestion,
  faRightFromBracket,
  faLock,
} from '@fortawesome/free-solid-svg-icons';

import SidebarMenu from 'react-bootstrap-sidebar-menu';

import {
  Link,
} from 'react-router-dom';

export default function PageNavigation({ variant, user, navigate }) {
  const textColor = variant === 'light' ? 'text-white' : 'text-secondary';

  const handleLogout = useCallback(() => {
    window.sessionStorage.removeItem('a');
    navigate('/login');
  }, [navigate]);

  return (
    <SidebarMenu
      expand="lg"
      className="page-navigation d-flex flex-column justify-content-start bg-primary"
    >
      <SidebarMenu.Header className="w-100">
        <SidebarMenu.Brand className="ps-3">
          <span className="fw-bold text-white">Qwik</span>
          <span className="fw-light text-white">Care</span>
        </SidebarMenu.Brand>
      </SidebarMenu.Header>
      <SidebarMenu.Body>
        <SidebarMenu.Nav>
          <SidebarMenu.Nav.Link as={Link} to="/">
            <SidebarMenu.Nav.Icon>
              <FontAwesomeIcon icon={faHouse} className={textColor} style={{ width: '22px' }} />
            </SidebarMenu.Nav.Icon>
            <SidebarMenu.Nav.Title className="text-white">Home</SidebarMenu.Nav.Title>
          </SidebarMenu.Nav.Link>
          <SidebarMenu.Nav.Link as={Link} to="/scan">
            <SidebarMenu.Nav.Icon>
              <FontAwesomeIcon icon={faQrcode} className={textColor} style={{ width: '22px' }} />
            </SidebarMenu.Nav.Icon>
            <SidebarMenu.Nav.Title className="text-white">Scan</SidebarMenu.Nav.Title>
          </SidebarMenu.Nav.Link>
          <hr className="text-white" />
          <SidebarMenu.Nav.Link as={Link} to="/my-data">
            <SidebarMenu.Nav.Icon>
              <FontAwesomeIcon icon={faFolderOpen} className={textColor} style={{ width: '22px' }} />
            </SidebarMenu.Nav.Icon>
            <SidebarMenu.Nav.Title className="text-white">My Data</SidebarMenu.Nav.Title>
          </SidebarMenu.Nav.Link>
          <SidebarMenu.Nav.Link as={Link} to="/check-symptoms">
            <SidebarMenu.Nav.Icon>
              <FontAwesomeIcon icon={faMagnifyingGlass} className={textColor} style={{ width: '22px' }} />
            </SidebarMenu.Nav.Icon>
            <SidebarMenu.Nav.Title className="text-white">Check Symptoms</SidebarMenu.Nav.Title>
          </SidebarMenu.Nav.Link>
          <SidebarMenu.Nav.Link as={Link} to="/social-groups">
            <SidebarMenu.Nav.Icon>
              <FontAwesomeIcon icon={faUserGroup} className={textColor} style={{ width: '22px' }} />
            </SidebarMenu.Nav.Icon>
            <SidebarMenu.Nav.Title className="text-white">Social Groups</SidebarMenu.Nav.Title>
          </SidebarMenu.Nav.Link>
          <SidebarMenu.Nav.Link as={Link} to="/my-health">
            <SidebarMenu.Nav.Icon>
              <FontAwesomeIcon icon={faHeartPulse} className={textColor} style={{ width: '22px' }} />
            </SidebarMenu.Nav.Icon>
            <SidebarMenu.Nav.Title className="text-white">My Health</SidebarMenu.Nav.Title>
          </SidebarMenu.Nav.Link>
          <SidebarMenu.Nav.Link as={Link} to="/find-care">
            <SidebarMenu.Nav.Icon>
              <FontAwesomeIcon icon={faNotesMedical} className={textColor} style={{ width: '22px' }} />
            </SidebarMenu.Nav.Icon>
            <SidebarMenu.Nav.Title className="text-white">Find Care</SidebarMenu.Nav.Title>
          </SidebarMenu.Nav.Link>
          <SidebarMenu.Nav.Link as={Link} to="/share-records">
            <SidebarMenu.Nav.Icon>
              <FontAwesomeIcon icon={faShare} className={textColor} style={{ width: '22px' }} />
            </SidebarMenu.Nav.Icon>
            <SidebarMenu.Nav.Title className="text-white">Share Records</SidebarMenu.Nav.Title>
          </SidebarMenu.Nav.Link>
          <SidebarMenu.Nav.Link as={Link} to="/appointments">
            <SidebarMenu.Nav.Icon>
              <FontAwesomeIcon icon={faCalendar} className={textColor} style={{ width: '22px' }} />
            </SidebarMenu.Nav.Icon>
            <SidebarMenu.Nav.Title className="text-white">Appointments</SidebarMenu.Nav.Title>
          </SidebarMenu.Nav.Link>
          <SidebarMenu.Nav.Link as={Link} to="/authorized-providers">
            <SidebarMenu.Nav.Icon>
              <FontAwesomeIcon icon={faKey} className={textColor} style={{ width: '22px' }} />
            </SidebarMenu.Nav.Icon>
            <SidebarMenu.Nav.Title className="text-white">Authorized Providers</SidebarMenu.Nav.Title>
          </SidebarMenu.Nav.Link>
          <hr className="text-white" />
          {!!user.permissions && !!user.permissions.length && (
            <SidebarMenu.Nav.Link as={Link} to="/admin">
              <SidebarMenu.Nav.Icon>
                <FontAwesomeIcon icon={faLock} className={textColor} style={{ width: '22px' }} />
              </SidebarMenu.Nav.Icon>
              <SidebarMenu.Nav.Title className="text-white">Admin</SidebarMenu.Nav.Title>
            </SidebarMenu.Nav.Link>
          )}
          <SidebarMenu.Nav.Link as={Link} to="/account-settings">
            <SidebarMenu.Nav.Icon>
              <FontAwesomeIcon icon={faUser} className={textColor} style={{ width: '22px' }} />
            </SidebarMenu.Nav.Icon>
            <SidebarMenu.Nav.Title className="text-white">Account Settings</SidebarMenu.Nav.Title>
          </SidebarMenu.Nav.Link>
          <SidebarMenu.Nav.Link as={Link} to="/help">
            <SidebarMenu.Nav.Icon>
              <FontAwesomeIcon icon={faCircleQuestion} className={textColor} style={{ width: '22px' }} />
            </SidebarMenu.Nav.Icon>
            <SidebarMenu.Nav.Title className="text-white">Help</SidebarMenu.Nav.Title>
          </SidebarMenu.Nav.Link>
          <SidebarMenu.Nav.Link onClick={handleLogout}>
            <SidebarMenu.Nav.Icon>
              <FontAwesomeIcon icon={faRightFromBracket} className={textColor} style={{ width: '22px' }} />
            </SidebarMenu.Nav.Icon>
            <SidebarMenu.Nav.Title className="text-white">Logout</SidebarMenu.Nav.Title>
          </SidebarMenu.Nav.Link>
        </SidebarMenu.Nav>
      </SidebarMenu.Body>
    </SidebarMenu>
  );
}

PageNavigation.propTypes = {
  variant: PropTypes.string,
  user: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  navigate: PropTypes.func.isRequired,
};

PageNavigation.defaultProps = {
  variant: null,
  user: null,
};
/*
    <>
      <Nav className="navbar-nav mt-5">

        <Nav.Link onClick={() => navigate('/my-data')} className="w-100">
          <FontAwesomeIcon icon={faFolderOpen} className={textColor} style={{ width: '22px' }} />
          <span className="ms-3">My Data</span>
        </Nav.Link>
        <Nav.Link onClick={() => navigate('/check-symptoms')} className="w-100">
      <FontAwesomeIcon icon={faMagnifyingGlass} className={textColor} style={{ width: '22px' }} />
          <span className="ms-3">Virtual Doctor</span>
        </Nav.Link>
        <Nav.Link onClick={() => navigate('/social-groups')} className="w-100">
          <FontAwesomeIcon icon={faUserGroup} className={textColor} style={{ width: '22px' }} />
          <span className="ms-3">Social Groups</span>
        </Nav.Link>
        <Nav.Link onClick={() => navigate('/my-health')} className="w-100">
          <FontAwesomeIcon icon={faHeartPulse} className={textColor} style={{ width: '22px' }} />
          <span className="ms-3">My Health</span>
        </Nav.Link>
        <Nav.Link onClick={() => navigate('/find-care')} className="w-100">
          <FontAwesomeIcon icon={faNotesMedical} className={textColor} style={{ width: '22px' }} />
          <span className="ms-3">Find Care</span>
        </Nav.Link>
        <hr />
        <Nav.Link className="w-100">
          <FontAwesomeIcon icon={faShare} className={textColor} style={{ width: '22px' }} />
          <span className="ms-3">Share Records</span>
        </Nav.Link>
        <Nav.Link className="w-100">
          <FontAwesomeIcon icon={faCalendar} className={textColor} style={{ width: '22px' }} />
          <span className="ms-3">Appointments</span>
        </Nav.Link>
        <Nav.Link className="w-100">
          <FontAwesomeIcon icon={faKey} className={textColor} style={{ width: '22px' }} />
          <span className="ms-3">Authorized Providers</span>
        </Nav.Link>
        <hr />
        {!!user.permissions && !!user.permissions.length && (
          <Nav.Link className="w-100" onClick={() => navigate('/admin')}>
            <FontAwesomeIcon icon={faLock} className={textColor} style={{ minWidth: '22px' }} />
            <span className="ms-3">Admin</span>
          </Nav.Link>
        )}
        <Nav.Link onClick={() => navigate('/account')} className="w-100">
          <FontAwesomeIcon icon={faUser} className={textColor} style={{ width: '22px' }} />
          <span className="ms-3">Account Settings</span>
        </Nav.Link>
        <Nav.Link className="w-100">
       <FontAwesomeIcon icon={faCircleQuestion} className={textColor} style={{ width: '22px' }} />
          <span className="ms-3">Help</span>
        </Nav.Link>
        <Nav.Link className="w-100" onClick={handleLogout}>
     <FontAwesomeIcon icon={faRightFromBracket} className={textColor} style={{ width: '22px' }} />
          <span className="ms-3">Logout</span>
        </Nav.Link>
      </Nav>
    </>
    */
