import React from 'react';
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/Image';

import Card from 'react-bootstrap/Card';

export default function ConditionCard({
  condition,
  provider,
  providerImage,
  icon,
  date,
  onClick,
  className,
}) {
  return (
    <Card
      className={`shadow pt-2 border-0 w-200 h-100 ${className} condition-card`}
      role="button"
      onClick={onClick}
      style={{ minWidth: '250px', marginRight: '20px' }}
    >
      <Card.Body className="pb-1 pb-sm-1 pt-2 d-flex flex-column align-items-start">
        <Card.Title style={{ color: 'grey' }} className="text-center mt-auto text-sm-start condition-card__date">{date}</Card.Title>
        <Card.Title style={{ color: 'black' }} className="pb-4 text-center mt-auto text-sm-start condition-card__title">{condition}</Card.Title>
        <div style={{ position: 'absolute', top: '0px', right: '14px' }}>
          <Card.Img src={icon} variant="right" style={{ width: '40px', height: '40px' }} className="m-auto mt-3" />
        </div>
        <div className="d-flex flex-row align-items-start pb-2">
          <Image
            src={providerImage}
            className="object-fit-contain bg-white border border-0"
            roundedCircle
            style={{ height: '20px', width: '20px' }}
          />
          <Card.Text style={{ color: 'black' }} className="ms-2 text-center mt-auto text-sm-start condition-card__doc">{provider}</Card.Text>
        </div>
      </Card.Body>
    </Card>
  );
}

ConditionCard.propTypes = {
  condition: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  providerImage: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ConditionCard.defaultProps = {
  className: '',
};
