import React from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import {
  Link,
} from 'react-router-dom';

export default function ConfirmView() {
  return (
    <Container className="py-4">
      <h1 className="text-center mb-4">Confirm Check-In</h1>
      <Row>
        <Col sm={{ offset: 3, span: 6 }}>
          <Card>
            <Card.Body className="pt-4">
              <Card.Text className="text-center mb-4">
                <p>You are adding your information to the following location:</p>
                <strong>Lemur Hospital</strong>
              </Card.Text>
              <Button as={Link} to="/scan/pay" variant="primary" className="w-100">Confirm</Button>
              <Button variant="transparent" className="w-100">Cancel</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
