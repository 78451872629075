import React, {
} from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';

import { Steps } from 'intro.js-react';

import {
  useNavigate,
  useOutletContext,
} from 'react-router-dom';

import SocialCard from '../../components/social-card/social-card';
// import Notifications from '../../components/notifications/notifications';

export default function SocialGroupsView() {
  const { showTour } = useOutletContext();
  const navigate = useNavigate();
  const steps = [
    {
      element: '.my-health__list',
      intro: '<strong>My Health</strong>: Your health profile contains information that helps you and your provider keep track of your health.  Review this information and click the <strong>Verify</strong> button to continue.',
    },
  ];
  return (
    <Container className="my-health px-4">
      <Steps
        enabled={showTour}
        steps={steps}
        initialStep={0}
        onExit={() => {}}
        options={{
          showBullets: false,
        }}
      />
      <Row className="diagnoses pb-1 px-2 bg-white rounded-bottom-3">
        <Col style={{ color: '#5e63f1', fontSize: '2em' }}>
          Social Groups
        </Col>
      </Row>
      <Row className="search-lemur pb-4 px-2 bg-white">
        <Col>
          <Form className="mb-3">
            <InputGroup size="lg" className="border border-1 rounded-1">
              <InputGroup.Text className="search-lemur text-dark bg-light rounded-start-1 border-end-0 pe-1">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search Social Groups"
                className="rounded-end-1 border-start-0 bg-light text-dark placeholder-dark"
              />
            </InputGroup>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          {/* Need to replace with data fill */}
          <Row className="pb-4">
            <SocialCard
              socialName="Family"
              dateJoined="Member since 2/20/2024"
              nextMeetingDate="No upcoming meetings"
              isMeetingToday={false}
              isMessagePending
              onClick={() => navigate('/social-groups')}
            />
          </Row>
          <Row className="pb-4">
            <SocialCard
              socialName="Friends"
              dateJoined="Member since 1/18/2024"
              nextMeetingDate="No upcoming meetings"
              isMeetingToday={false}
              isMessagePending={false}
              onClick={() => navigate('/social-groups')}
            />
          </Row>
          <Row className="pb-4">
            <SocialCard
              socialName="Diabetes Support"
              dateJoined="Member since 7/5/2024"
              nextMeetingDate="Meeting 3/11/2019 at 1:15PM"
              isMeetingToday={false}
              isMessagePending={false}
              onClick={() => navigate('/social-groups')}
            />
          </Row>
          <Row className="pb-4">
            <SocialCard
              socialName="AA"
              dateJoined="Member since 2/20/2024"
              nextMeetingDate="Meeting today at 3:30PM"
              isMeetingToday
              isMessagePending
              onClick={() => navigate('/social-groups')}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
